import $ from 'jquery';
import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
//import storeComponents from 'rp-frontend-components/dist/store/store';
import loadable from '@loadable/component';
import { ThemeProvider } from 'styled-components';
import { theme, themeCM, themeVA, themeURB } from '@navent-re/global-styles';
import { isVAMX, isCMBR, isURB } from '@navent-re/utilities';
import { createStore } from 'redux';
import reducers from './ducks/reducers';

$(() => {
	const storeReducers = createStore(reducers);
	const HomeApp = loadable(() => import('./components/home'));
	const homeProps = {
		'siteInfo': siteInfo,
		'showGuaranty': showGuaranty,
		'guaranty': guaranty,
		'headerLinks': headerLinks,
		'cookies': cookies,
		'excludeExternalJs': excludeExternalJs,
		'filters': filters,
		'isMobile': isMobile,
		'showGuarantyFooterLink': showGuarantyFooterLink,
		'enableViewPhone': enableViewPhone,
		'valueProposal': valueProposal,
		'notices': notices,
		'googleClientId': googleClientId,
		'facebookAppId': facebookAppId,
		'loginAttributes': loginAttributes,
		'enableHeadbandAppMobile': enableHeadbandAppMobile,
		'urlProfesional': urlProfesional,
		'mirrorRedirectToLanding': mirrorRedirectToLanding,
		'enableFeedbackNotification': enableFeedbackNotification,
		'email': email,
		'isRecuperarEmail': isRecuperarEmail,
		'isBlanquearEmail': isBlanquearEmail,
		'enableCountryCodeInput': enableCountryCodeInput,
		'transactionalContentEnabled': transactionalContentEnabled,
		'enableContactHeader': enableContactHeader,
	}

	const root = ReactDOM.createRoot(document.getElementById('react-home-app'));

	root.render(
		<ThemeProvider theme={ isCMBR(siteInfo.id) ? themeCM : isVAMX(siteInfo.id) ? themeVA : isURB(siteInfo.id) ? themeURB : theme }>
			<Provider store={storeReducers}>
				<HomeApp homeProps={homeProps}/>
			</Provider>
		</ThemeProvider>
	)
});
