import { initialList } from "../../store/initialStores";

const listReducer = (state = {}, action) => {
	switch (action.type) {
	case 'SET_FILTERS':
		return {
			...state,
			filters: action.filters
		}
	}
	return state;
}

export default listReducer